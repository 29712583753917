/* tslint:disable */
export type Maybe<T> = T | null;
export type InputMaybe<T> = Maybe<T>;
export type Exact<T extends { [key: string]: unknown }> = { [K in keyof T]: T[K] };
export type MakeOptional<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]?: Maybe<T[SubKey]> };
export type MakeMaybe<T, K extends keyof T> = Omit<T, K> & { [SubKey in K]: Maybe<T[SubKey]> };
export type MakeEmpty<T extends { [key: string]: unknown }, K extends keyof T> = { [_ in K]?: never };
export type Incremental<T> = T | { [P in keyof T]?: P extends ' $fragmentName' | '__typename' ? T[P] : never };
/** All built-in and custom scalars, mapped to their actual values */
export type Scalars = {
  ID: { input: string; output: string; }
  String: { input: string; output: string; }
  Boolean: { input: boolean; output: boolean; }
  Int: { input: number; output: number; }
  Float: { input: number; output: number; }
  DateTime: { input: any; output: any; }
  JSON: { input: any; output: any; }
  ValueByCurrency: { input: any; output: any; }
};

export type AccountFilterValue = {
  count?: Maybe<Scalars['Int']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

/** Account validation results. */
export type AccountValidationResult = {
  /** Account Id */
  accountId: Scalars['String']['output'];
  /** Account address Ids */
  addressIds: Array<Scalars['String']['output']>;
  /** Validation results. */
  validationResults: Array<ValidationResult>;
};

export type Address = {
  accountId: Scalars['String']['output'];
  addressId: Scalars['String']['output'];
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
  realAddressId?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

/** Alternative address output. */
export type AlternativeAddress = {
  city: Scalars['String']['output'];
  country: Scalars['String']['output'];
  email?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  phone?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  street1: Scalars['String']['output'];
  street2?: Maybe<Scalars['String']['output']>;
  zipCode: Scalars['String']['output'];
};

/** Alternative address model. */
export type AlternativeAddressInput = {
  city: Scalars['String']['input'];
  country: Scalars['String']['input'];
  email?: InputMaybe<Scalars['String']['input']>;
  name: Scalars['String']['input'];
  phone?: InputMaybe<Scalars['String']['input']>;
  state?: InputMaybe<Scalars['String']['input']>;
  street1: Scalars['String']['input'];
  street2?: InputMaybe<Scalars['String']['input']>;
  zipCode: Scalars['String']['input'];
};

/** Collection of grouped basket items. */
export type Basket = {
  /** Allow user to ship in full option */
  allowUserToSpecifyShipOrderInFullForNowOrder?: Maybe<Scalars['Boolean']['output']>;
  alternativeAddress?: Maybe<AlternativeAddress>;
  /** The user who asked for a review */
  assignee?: Maybe<BasketUser>;
  /** The user who's asked for reviewing the basket */
  assigner?: Maybe<BasketUser>;
  /** Basket identifier. */
  basketId: Scalars['String']['output'];
  /** Basket items flat list. */
  basketItems: Array<BasketItem>;
  /** Total values for bulks. */
  bulkTotal: Total;
  /** Indicates when the check order expires. */
  checkOrderExpiryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Order reference number provided by client. */
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  /**
   * Brand org defines if delivery of the order can be scheduled for an exact day, or week, or month
   * Its part of product.accountData, but seems to be legit for basket level
   */
  deliveryWindowType: DeliveryWindowType;
  /** Entity tag for polling. */
  etag?: Maybe<Scalars['String']['output']>;
  /** Grouped basket items. */
  groups: Array<BasketRowsGroup>;
  /** Included materials and quantities for the given basket */
  materialsQuantities?: Maybe<Array<BasketQuantity>>;
  /** Order reference number. */
  orderReferenceNumber?: Maybe<Scalars['String']['output']>;
  /** Total values for non-bulks. */
  realTotal: Total;
  /** API Decides by the user viewing the basket */
  reviewNeeded?: Maybe<Scalars['Boolean']['output']>;
  /** Status of review */
  reviewStatus?: Maybe<ReviewStatus>;
  /** Selected shipping option id */
  selectedShippingOptionId?: Maybe<Scalars['String']['output']>;
  /** Shipping options */
  shippingOptions?: Maybe<Array<Maybe<ShippingOption>>>;
  /** Status of the basket. */
  status: BasketStatus;
  /** When the basket is placed */
  submittedTime?: Maybe<Scalars['String']['output']>;
  /** Totals of all child groups. */
  total: Total;
  /** Basket owner user name. */
  userDisplayName: Scalars['String']['output'];
  /** Basket owner user id. */
  userId: Scalars['String']['output'];
  /** Determines ship in full option */
  userSpecifiedGroupedDelivery?: Maybe<Scalars['Boolean']['output']>;
};

export type BasketAccount = {
  /** Account ID */
  accountId: Scalars['String']['output'];
  /** Extended address info */
  address?: Maybe<Address>;
  /** Address ID */
  addressId?: Maybe<Scalars['String']['output']>;
  /** Basket Account ID */
  basketAccountId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
};

/** Basket account reference. */
export type BasketAccountAddress = {
  /** Account identifier. */
  accountId: Scalars['String']['input'];
  /** Address identifier. */
  addressId: Scalars['String']['input'];
  /** Alternative address used for drop ship. */
  alternativeAddress?: InputMaybe<AlternativeAddressInput>;
  realAddressId?: InputMaybe<Scalars['String']['input']>;
};

/** Basket account used for MDO basket to represent items belonging to a single door. */
export type BasketAccountDelivery = {
  /** Account id. */
  accountId: Scalars['String']['input'];
  /** Account address id. */
  addressId: Scalars['String']['input'];
  /** Alternative address used for drop ship. */
  alternativeAddress?: InputMaybe<AlternativeAddressInput>;
  /** Order reference number provided by client. */
  clientReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Basket items. */
  items: Array<BasketUpdateItem>;
};

/** Basket row group details for ADDRESS group */
export type BasketAddressRowsGroupDetails = {
  accountId: Scalars['String']['output'];
  addressId: Scalars['String']['output'];
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  deletedAtUtc?: Maybe<Scalars['String']['output']>;
  externalAccountId: Scalars['String']['output'];
  realAddressId?: Maybe<Scalars['String']['output']>;
};

/** Basket clear. */
export type BasketCleanRequest = {
  /** Basket Id. */
  basketId: Scalars['String']['input'];
};

export type BasketColumn = DateBasketColumn | DiscountBasketColumn | OrderTypeBasketColumn | PriceBasketColumn | QuantityBasketColumn;

export enum BasketColumnKeys {
  CreatedDate = 'CREATED_DATE',
  DeliveryDate = 'DELIVERY_DATE',
  Discount = 'DISCOUNT',
  DiscountPercentage = 'DISCOUNT_PERCENTAGE',
  DiscountRrp = 'DISCOUNT_RRP',
  DisplayDateFrom = 'DISPLAY_DATE_FROM',
  Gross = 'GROSS',
  ManualDiscount = 'MANUAL_DISCOUNT',
  Markup = 'MARKUP',
  Net = 'NET',
  OrderType = 'ORDER_TYPE',
  Quantity = 'QUANTITY',
  Rrp = 'RRP',
  SimulatedTotal = 'SIMULATED_TOTAL',
  Total = 'TOTAL',
  Value = 'VALUE',
  ValueNet = 'VALUE_NET',
  ValueRrp = 'VALUE_RRP',
  Wsp = 'WSP'
}

/** Basket row group details for DELIVERY group */
export type BasketDeliveryRowsGroupDetails = {
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  consignmentEndDate?: Maybe<Scalars['String']['output']>;
  consignmentStartDate?: Maybe<Scalars['String']['output']>;
  deliveryId: Scalars['String']['output'];
  generatedClientReferenceNumber?: Maybe<Scalars['String']['output']>;
  isConsignmentOrder?: Maybe<Scalars['Boolean']['output']>;
  requestedDeliveryDate?: Maybe<Scalars['String']['output']>;
};

/** Basket email recipient. */
export type BasketEmailRecipient = {
  /** Recipient name. */
  displayName?: InputMaybe<Scalars['String']['input']>;
  /** Recipient email. */
  email: Scalars['String']['input'];
};

/** Basket email request. */
export type BasketEmailRequest = {
  /** Email message. */
  message: Scalars['String']['input'];
  /** Email recipients. */
  recipients: Array<BasketEmailRecipient>;
};

/** Sorting types in scope of a group. */
export enum BasketGroupSortType {
  Alphabetical = 'ALPHABETICAL',
  CreatedDate = 'CREATED_DATE',
  Delivery = 'DELIVERY',
  Markup = 'MARKUP',
  Quantity = 'QUANTITY',
  Rrp = 'RRP',
  TotalNet = 'TOTAL_NET',
  TotalRrp = 'TOTAL_RRP',
  TotalWsp = 'TOTAL_WSP',
  Wsp = 'WSP'
}

/** Basket header with order information. */
export type BasketHeader = {
  /** Basket identifier. */
  basketId: Scalars['String']['input'];
  /** Order reference number provided by client. */
  clientReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Order reference number. */
  orderReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  /** Property bag provided by client. */
  propertyBag?: InputMaybe<Scalars['JSON']['input']>;
  /** Selected shipping option id */
  selectedShippingOptionId?: InputMaybe<Scalars['String']['input']>;
  /** User specified grouped delivery. */
  userSpecifiedGroupedDelivery?: InputMaybe<Scalars['Boolean']['input']>;
};

export type BasketItem = {
  /** Basket item account id. */
  accountId: Scalars['String']['output'];
  /** Basket item address id. */
  addressId?: Maybe<Scalars['String']['output']>;
  /** ID for the article of the product */
  articleId: Scalars['String']['output'];
  /** Basket item id. */
  basketItemId: Scalars['String']['output'];
  /** Combined tracking info from source and originInfomation */
  basketItemOrigin?: Maybe<BasketItemOrigin>;
  /** Unique Brand ID in ERP systems. */
  brandId: Scalars['String']['output'];
  /** Confirmed order number. */
  confirmedOrderNumber?: Maybe<Scalars['String']['output']>;
  /** Confirmed quantity. */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Consignment end date */
  consignmentEndDate?: Maybe<Scalars['String']['output']>;
  /** Consignment start date */
  consignmentStartDate?: Maybe<Scalars['String']['output']>;
  /** Basket Item creation date */
  created?: Maybe<Scalars['String']['output']>;
  displayDateFrom?: Maybe<Scalars['DateTime']['output']>;
  displayDateTo?: Maybe<Scalars['DateTime']['output']>;
  /** Unique Product displayed ID. */
  displayedProductId: Scalars['String']['output'];
  /** Expected delivery date. */
  expectedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Basket Item inventory id */
  inventoryId?: Maybe<Scalars['String']['output']>;
  /** Basket Item last update date */
  lastUpdated?: Maybe<Scalars['String']['output']>;
  /** ID for the material of the product */
  materialId?: Maybe<Scalars['String']['output']>;
  /** Optional note attached to the item */
  note?: Maybe<Scalars['String']['output']>;
  /** Basket item order type now|future. */
  orderType: OrderType;
  /** Array of IDs for the materials within the prepack */
  prepackMaterialIds?: Maybe<Array<Scalars['String']['output']>>;
  /** Prepack size. Optional. */
  prepackSize?: Maybe<Scalars['Int']['output']>;
  /** Unique Product ID in ERP systems. */
  productId: Scalars['String']['output'];
  /** Type of the product. */
  productType: BasketItemProductType;
  /** Requested delivery date. */
  requestedDeliveryDate: Scalars['DateTime']['output'];
  /** Requested quantity. */
  requestedQuantity: Scalars['Int']['output'];
  /** Prepack size. Optional. */
  title?: Maybe<Scalars['String']['output']>;
};

/** Basket item validation result. */
export type BasketItemCheckOrderResult = {
  /** Basket item id. */
  basketItemId: Scalars['String']['output'];
  /** Confirmed quantity. */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Expected delivery date. */
  expectedDeliveryDate?: Maybe<Scalars['DateTime']['output']>;
  /** Basket item prices. */
  prices?: Maybe<BasketItemPrice>;
  /** Relevant product id. */
  productId: Scalars['String']['output'];
  /** Basket item validation results. */
  validationResults?: Maybe<Array<ValidationResult>>;
};

export type BasketItemOrigin = {
  originInformation?: Maybe<Scalars['String']['output']>;
  source?: Maybe<Scalars['String']['output']>;
};

/** Prices of an basket item. */
export type BasketItemPrice = {
  /** Discounted gross price. */
  discountedGrossPrice?: Maybe<BasketPrice>;
  /** Gross price. */
  grossPrice?: Maybe<BasketPrice>;
  /** Net price. */
  netPrice?: Maybe<BasketPrice>;
  /** Retail price. */
  retailPrice?: Maybe<BasketPrice>;
};

/** Type of products that can be presented in a basket item. */
export enum BasketItemProductType {
  Prepack = 'PREPACK',
  Size = 'SIZE'
}

export type BasketItemSourceInput = {
  type: Scalars['String']['input'];
};

/** Basket item validation result. */
export type BasketItemUpdateResult = {
  /** Basket item id, used to update existing basket items. */
  basketItemId?: Maybe<Scalars['String']['output']>;
  /** Brand identifier. */
  brandId: Scalars['String']['output'];
  /** Consignment End Date. */
  consignmentEndDate?: Maybe<Scalars['String']['output']>;
  /** Consignment Start Date. */
  consignmentStartDate?: Maybe<Scalars['String']['output']>;
  displayDateFrom?: Maybe<Scalars['DateTime']['output']>;
  displayDateTo?: Maybe<Scalars['DateTime']['output']>;
  /** Inventory Id. */
  inventoryId?: Maybe<Scalars['String']['output']>;
  /** Basket item note. */
  note?: Maybe<Scalars['String']['output']>;
  /** Basket item order type now|future. */
  orderType?: Maybe<OrderType>;
  /** Product identifier. */
  productId: Scalars['String']['output'];
  /** Requeued delivery date for future orders. */
  requestedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Requested quantity for basket item. */
  requestedQuantity: Scalars['Int']['output'];
  /** Basket items validation results. */
  validationResults?: Maybe<Array<ValidationResult>>;
};

export type BasketLineThumbnail = ITotalProductItem & {
  /** Basket item id. */
  basketItemIds: Array<Scalars['String']['output']>;
  /** Brand id. */
  brandId: Scalars['String']['output'];
  /** Image of material. */
  imageUrl: Scalars['String']['output'];
  previewItems: Array<PreviewItem>;
  /** Product Id. */
  productId: Scalars['String']['output'];
  /** Quantity of basket items of material. */
  quantity: Scalars['Int']['output'];
  /** Title of the product. */
  title: Scalars['String']['output'];
};

export type BasketManagerAccount = {
  accountId: Scalars['String']['output'];
  domainId?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

export type BasketManagerAddress = {
  account: BasketManagerAccount;
  addressId?: Maybe<Scalars['String']['output']>;
  box?: Maybe<Scalars['String']['output']>;
  city?: Maybe<Scalars['String']['output']>;
  country?: Maybe<Scalars['String']['output']>;
  countryCode?: Maybe<Scalars['String']['output']>;
  externalId?: Maybe<Scalars['String']['output']>;
  isDropship: Scalars['Boolean']['output'];
  name?: Maybe<Scalars['String']['output']>;
  state?: Maybe<Scalars['String']['output']>;
  stateCode?: Maybe<Scalars['String']['output']>;
  street?: Maybe<Scalars['String']['output']>;
  zipCode?: Maybe<Scalars['String']['output']>;
};

export type BasketManagerBrand = {
  id: Scalars['String']['output'];
  logoUrl?: Maybe<Scalars['String']['output']>;
  name?: Maybe<Scalars['String']['output']>;
};

/** Filtering information for basket manager requests. */
export type BasketManagerFilterQuery = {
  /** Account to filter. */
  account?: InputMaybe<Scalars['String']['input']>;
  /** Account to filter. */
  accounts?: InputMaybe<Array<Scalars['String']['input']>>;
  /** Brands to filter. */
  brand?: InputMaybe<Scalars['String']['input']>;
  /** Users to filter. */
  createdDateFrom?: InputMaybe<Scalars['String']['input']>;
  /** Users to filter. */
  createdDateTo?: InputMaybe<Scalars['String']['input']>;
  /** Configurability for the filters */
  includedFilters: Array<InputMaybe<BasketManagerFilters>>;
  /** Order type to filter. */
  orderType?: InputMaybe<Scalars['String']['input']>;
  /** Search query. */
  query?: InputMaybe<Scalars['String']['input']>;
  /** Basket status to filter. */
  status?: InputMaybe<BasketStatus>;
  /** Users to filter. */
  user?: InputMaybe<Scalars['String']['input']>;
};

/** Response containing all filter values. */
export type BasketManagerFilterValues = {
  accountFilter: Array<AccountFilterValue>;
  brandFilter: Array<SimpleFilterValue>;
  orderTypeFilter: Array<SimpleFilterValue>;
  statusFilter: Array<SimpleFilterValue>;
  userFilter: Array<SimpleFilterValue>;
};

export enum BasketManagerFilters {
  Account = 'Account',
  Brand = 'Brand',
  DatesRange = 'DatesRange',
  OrderType = 'OrderType',
  Status = 'Status',
  User = 'User'
}

/** Basket without item information */
export type BasketManagerInfo = {
  /** Basket accounts */
  basketAccounts?: Maybe<Array<Maybe<BasketAccount>>>;
  basketId: Scalars['String']['output'];
  /** Brands in basket */
  brands?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Custom reference number or name */
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  /** Basket's creator */
  createdBy?: Maybe<BasketUser>;
  /** Basket creation time */
  creationTime: Scalars['DateTime']['output'];
  /** Basket last updated time */
  lastUpdatedTime?: Maybe<Scalars['DateTime']['output']>;
  orderReferenceNumber: Scalars['String']['output'];
  /** Status of the basket */
  status?: Maybe<Scalars['String']['output']>;
  /** Basket Summary */
  summary?: Maybe<Summary>;
  /** Basket User */
  user?: Maybe<BasketUser>;
};

export type BasketManagerItem = {
  addresses: Array<BasketManagerAddress>;
  basketId: Scalars['String']['output'];
  brands: Array<BasketManagerBrand>;
  checkOrderExpiryUtc?: Maybe<Scalars['DateTime']['output']>;
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  createdBy: UserExtended;
  currency?: Maybe<Scalars['String']['output']>;
  hasDiscount: Scalars['Boolean']['output'];
  lastUpdatedUtc?: Maybe<Scalars['DateTime']['output']>;
  net: Scalars['Float']['output'];
  orderReferenceNumber?: Maybe<Scalars['String']['output']>;
  orderType?: Maybe<OrderType>;
  quantity: Scalars['Int']['output'];
  rrp: Scalars['Float']['output'];
  rrpCurrency?: Maybe<Scalars['String']['output']>;
  status?: Maybe<BasketStatus>;
  submittedBy?: Maybe<UserExtended>;
  value: Scalars['Float']['output'];
  wsp: Scalars['Float']['output'];
  wspCurrency?: Maybe<Scalars['String']['output']>;
};

/** Basket manager list request. */
export type BasketManagerListRequest = {
  /** Is the sorting ascending or descending. */
  ascending?: InputMaybe<Scalars['Boolean']['input']>;
  /** Filtering data. */
  filters?: InputMaybe<BasketManagerFilterQuery>;
  /** Which property to order by. */
  orderBy?: InputMaybe<BasketManagerOrderByType>;
  /** Which page to get. */
  page?: InputMaybe<Scalars['Int']['input']>;
  /** How many items per page. */
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Types or sorting for basket manager. */
export enum BasketManagerOrderByType {
  ClientReferenceNumber = 'ClientReferenceNumber',
  LastUpdatedUtc = 'LastUpdatedUtc',
  OrderReferenceNumber = 'OrderReferenceNumber',
  Quantity = 'Quantity',
  Value = 'Value'
}

/** Response containing list of requested items. */
export type BasketManagerResult = {
  data: Array<BasketManagerItem>;
  page: Scalars['Int']['output'];
  size: Scalars['Int']['output'];
  total: Scalars['Int']['output'];
};

/** Basket Mapping Settings */
export type BasketMappingConfig = {
  additionalPrefix?: InputMaybe<Scalars['String']['input']>;
  basketItemCategoryAttributeKey?: InputMaybe<Scalars['String']['input']>;
  defaultGroupingName?: InputMaybe<Scalars['String']['input']>;
  productTitleLevel?: InputMaybe<Scalars['Int']['input']>;
  productTitleLevelString?: InputMaybe<Scalars['String']['input']>;
};

/** Represents one of prices of basket item. */
export type BasketPrice = {
  /** Currency. */
  currency: Scalars['String']['output'];
  /** Price. */
  price?: Maybe<Scalars['Float']['output']>;
  /** Unit price. */
  unitPrice?: Maybe<Scalars['Float']['output']>;
};

/** Basket quantity entity */
export type BasketQuantity = {
  /** Amount */
  amount: Scalars['Int']['output'];
  /** Brand identifier */
  brandId?: Maybe<Scalars['String']['output']>;
  /** Product identifier */
  productId: Scalars['String']['output'];
  /** Product type */
  productType: Scalars['String']['output'];
};

/**
 * Basket row view represents information about basket items.
 * In DB each basket item is linked to a sellable product (size or prepack).
 * On UI we operate with materials or prepacks.
 * Prepacks can not be grouped by materials because they may contain multiple materials inside.
 */
export type BasketRow = {
  /** List of associated basket items. */
  basketItems: Array<BasketItem>;
  /** Total values for bulks. */
  bulkTotal: Total;
  columns: Array<BasketColumn>;
  /** Creation date of the first item */
  created?: Maybe<Scalars['String']['output']>;
  /** Respective delivery entity */
  delivery: BasketDeliveryRowsGroupDetails;
  /** Image of the material. */
  imageUrl: Scalars['String']['output'];
  /** Date when any item was updated */
  lastUpdated?: Maybe<Scalars['String']['output']>;
  materialWidthIds?: Maybe<Array<Maybe<MaterialWidth>>>;
  previewItems: Array<PreviewItem>;
  /** Price information that is related with the row. */
  priceInfo: BasketRowPrice;
  /** Product associated with the basket row. */
  product: BasketRowProduct;
  /** Total values for non-bulks. */
  realTotal: Total;
  /**
   * Total values.
   * rrp = RRP * Quantity.
   * wsp = (WSPD ?? WSP) * Quantity.
   */
  total: Total;
};

/** Prices that are related with a basket row. */
export type BasketRowPrice = {
  /** Currency. */
  currency?: Maybe<Scalars['String']['output']>;
  /** Rrp Currency. */
  currencyRrp?: Maybe<Scalars['String']['output']>;
  /**
   * Discount rate from 0 to 1. Zero means no discount.
   * discountRate = 1 - (wspd ?? wsp)/wsp.
   */
  discountRate?: Maybe<Scalars['Float']['output']>;
  /** Displayed WSP. */
  displayedwsp?: Maybe<Scalars['Float']['output']>;
  /** Manual Discount Rate */
  manualDiscount?: Maybe<Scalars['Float']['output']>;
  /** The ratio between the WSP and the RRP. */
  markUp?: Maybe<Scalars['Float']['output']>;
  /** Net price. */
  net?: Maybe<Scalars['Float']['output']>;
  /** Recommended retail price. */
  rrp?: Maybe<Scalars['Float']['output']>;
  /**
   * Wholesale price.
   * Gross price.
   */
  wsp?: Maybe<Scalars['Float']['output']>;
  /**
   * Discounted WSP.
   * Optional.
   */
  wspd?: Maybe<Scalars['Float']['output']>;
};

/** Basket row product. */
export type BasketRowProduct = {
  /** Id of the article this product belongs to */
  articleId: Scalars['String']['output'];
  /** Title of the article this product belongs to */
  articleTitle: Scalars['String']['output'];
  /** Unique Brand ID in ERP systems. */
  brandId: Scalars['String']['output'];
  /** Color */
  color: Scalars['String']['output'];
  /** Color code */
  colorCode: Scalars['String']['output'];
  /** Id of the article formatted removing prefix. */
  displayedArticleId?: Maybe<Scalars['String']['output']>;
  /** Unique Product displayed ID. */
  displayedProductId: Scalars['String']['output'];
  /** Is closed out product */
  isclosedoutproduct?: Maybe<Scalars['Boolean']['output']>;
  /** Unique Product ID in ERP systems. */
  productId: Scalars['String']['output'];
  /** Product type. */
  productType: BasketRowProductType;
  /** Title of the product. */
  title: Scalars['String']['output'];
};

/** Type of products that can be presented in a basket row. */
export enum BasketRowProductType {
  Material = 'MATERIAL',
  Prepack = 'PREPACK'
}

/** Generic group for basket items. */
export type BasketRowsGroup = {
  /** Totals of bulk child entries. */
  bulkTotal: Total;
  columns: Array<BasketColumn>;
  /** Creation date of the first item */
  created?: Maybe<Scalars['String']['output']>;
  /** Group details */
  details?: Maybe<BasketRowsGroupDetailsUnion>;
  /** Nested groups if presented. Optional. */
  groups?: Maybe<Array<BasketRowsGroup>>;
  /**
   * ID of an entity that represents the group.
   * It can be `catalog`, `delivery date`, or `product id`.
   */
  id: Scalars['String']['output'];
  /** Image of the group */
  imageUrl?: Maybe<Scalars['String']['output']>;
  /** Date when any item was updated */
  lastUpdated?: Maybe<Scalars['String']['output']>;
  /** Totals of non-bulk child entries. */
  realTotal: Total;
  /**
   * Items it the group.
   * Empty if `childGroups` field is presented.
   */
  rows?: Maybe<Array<BasketRow>>;
  /** Subtitle of the group. */
  subTitle?: Maybe<Scalars['String']['output']>;
  thumbnails: Array<BasketLineThumbnail>;
  /** Display name of the group. */
  title: Scalars['String']['output'];
  /** Totals of all child entries. */
  total: Total;
  /** Type of the group. */
  type: BasketRowsGroupType;
};

/** Union for group details */
export type BasketRowsGroupDetailsUnion = BasketAddressRowsGroupDetails | BasketDeliveryRowsGroupDetails;

/** Types of basket items grouping. */
export enum BasketRowsGroupType {
  Address = 'ADDRESS',
  Article = 'ARTICLE',
  Articlegroup = 'ARTICLEGROUP',
  Brand = 'BRAND',
  Businessunit = 'BUSINESSUNIT',
  Category = 'CATEGORY',
  Delivery = 'DELIVERY',
  Division = 'DIVISION',
  OrderNumber = 'ORDER_NUMBER'
}

/** Paginated result for lightweight basket model */
export type BasketSearchResult = {
  baskets?: Maybe<Array<Maybe<BasketManagerInfo>>>;
  totalResultCount?: Maybe<Scalars['Int']['output']>;
};

export enum BasketSearchSortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

export enum BasketSearchSortType {
  LastUpdatedTime = 'LAST_UPDATED_TIME',
  OrderReferenceNumber = 'ORDER_REFERENCE_NUMBER',
  SubmittedDateTime = 'SUBMITTED_DATE_TIME',
  TotalPiecesQuantity = 'TOTAL_PIECES_QUANTITY'
}

/** Basket basic informaion */
export type BasketSlim = {
  basketAccounts?: Maybe<Array<Maybe<BasketAccount>>>;
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  createdTime: Scalars['DateTime']['output'];
  id: Scalars['String']['output'];
  /** Total Basket Net Value per Currency */
  netTotalValueByCurrency?: Maybe<Scalars['ValueByCurrency']['output']>;
  orderReferenceNumber: Scalars['String']['output'];
  reviewStatus?: Maybe<ReviewStatus>;
  status?: Maybe<Scalars['String']['output']>;
  /** Total Basket product pieces count */
  totalQuantity: Scalars['Int']['output'];
  /** Total Basket Value per Currency */
  totalValueByCurrency?: Maybe<Scalars['ValueByCurrency']['output']>;
};

export enum BasketStatus {
  Accepted = 'ACCEPTED',
  Acknowledged = 'ACKNOWLEDGED',
  /** Submit failed. */
  Failed = 'FAILED',
  New = 'NEW',
  PaymentFailed = 'PAYMENT_FAILED',
  PaymentInitiated = 'PAYMENT_INITIATED',
  PaymentSuccess = 'PAYMENT_SUCCESS',
  /** Adding type to be used in review baskets */
  Pending = 'PENDING',
  Processed = 'PROCESSED',
  /** Beginning processing */
  Processing = 'PROCESSING',
  Rejected = 'REJECTED',
  Submitted = 'SUBMITTED'
}

/** Basket item. */
export type BasketUpdateItem = {
  /** Basket item id, used to update existing basket items. */
  basketItemId?: InputMaybe<Scalars['String']['input']>;
  /** Brand identifier. */
  brandId: Scalars['String']['input'];
  /** Consignment End Date. */
  consignmentEndDate?: InputMaybe<Scalars['String']['input']>;
  /** Consignment Start Date. */
  consignmentStartDate?: InputMaybe<Scalars['String']['input']>;
  displayDateFrom?: InputMaybe<Scalars['String']['input']>;
  displayDateTo?: InputMaybe<Scalars['String']['input']>;
  /** Inventory Id. */
  inventoryId?: InputMaybe<Scalars['String']['input']>;
  /** Basket item note. */
  note?: InputMaybe<Scalars['String']['input']>;
  /** Basket item order type now|future. */
  orderType: OrderType;
  /** Tracking information for basket item, if empty BE merges the exising originInformation */
  originInformation?: InputMaybe<Scalars['JSON']['input']>;
  /** Product identifier. */
  productId: Scalars['String']['input'];
  /** Requested delivery date for future orders. */
  requestedDeliveryDate?: InputMaybe<Scalars['String']['input']>;
  /** Requested quantity for basket item. */
  requestedQuantity: Scalars['Int']['input'];
  /** Tracking information for basket product, if empty BE merges the exising source */
  source: BasketItemSourceInput;
};

/** Basket update model. */
export type BasketUpdateRequest = {
  /** Basket header. */
  basketHeader: BasketHeader;
  /** Deliveries for the basket. */
  deliveries?: InputMaybe<Array<Delivery>>;
  /** Basket delivery items per account address. */
  deliveryItems: Array<BasketAccountDelivery>;
};

/** Basket update result. */
export type BasketUpdateResult = {
  /** Basket items validation results. */
  items?: Maybe<Array<BasketItemUpdateResult>>;
  /** Determines ship in full option */
  userSpecifiedGroupedDelivery?: Maybe<Scalars['Boolean']['output']>;
  /** Basket validation results. */
  validationResults?: Maybe<Array<ValidationResult>>;
};

/** Type for assignee/assigner */
export type BasketUser = {
  avatarSource?: Maybe<Scalars['Int']['output']>;
  displayName: Scalars['String']['output'];
  email: Scalars['String']['output'];
  imageLink?: Maybe<Scalars['String']['output']>;
  isBlocked?: Maybe<Scalars['Boolean']['output']>;
  preferredCultureInfo?: Maybe<Scalars['String']['output']>;
  status?: Maybe<Scalars['Int']['output']>;
  userId: Scalars['String']['output'];
};

/** Basket validation result. */
export type CheckOrderResult = {
  /** Basket account validation results. */
  accountValidationResults?: Maybe<Array<AccountValidationResult>>;
  /** Basket items validation results. */
  items?: Maybe<Array<BasketItemCheckOrderResult>>;
  /** Basket validation results. */
  validationResults?: Maybe<Array<ValidationResult>>;
};

export type DateBasketColumn = {
  actualDate?: Maybe<Scalars['String']['output']>;
  date?: Maybe<Scalars['String']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
  secondaryValue?: Maybe<Scalars['String']['output']>;
};

/** Represents a delivery for a basket. */
export type Delivery = {
  /** Basket validation results. */
  clientReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  /** ID of the delivery. */
  deliveryId: Scalars['String']['input'];
};

/** Types of delivery window. */
export enum DeliveryWindowType {
  Day = 'DAY',
  Month = 'MONTH',
  Week = 'WEEK'
}

export type DiscountBasketColumn = {
  actual?: Maybe<Scalars['Float']['output']>;
  discount?: Maybe<DiscountValue>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type DiscountValue = {
  percentage?: Maybe<Scalars['Float']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type FilterQuery = {
  filters?: InputMaybe<Array<InputMaybe<FilterQueryItem>>>;
  pagination?: InputMaybe<PaginationQuery>;
  partitioning?: InputMaybe<PartitionFilter>;
  search?: InputMaybe<Scalars['String']['input']>;
};

/** Search filter options. */
export type FilterQueryItem = {
  /** Defines filtering field. */
  fieldId: Scalars['String']['input'];
  /** Array of filter values. */
  values?: InputMaybe<Array<Scalars['String']['input']>>;
};

/** Search filter field type. */
export enum FilterType {
  Range = 'Range',
  Value = 'Value'
}

/** Filter value type. */
export type FilterValue = {
  /** Defines filter value facet hit count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Defines filter value display name. */
  displayName: Scalars['String']['output'];
  /** Defines filter value is disabled. */
  isDisabled?: Maybe<Scalars['Boolean']['output']>;
  /** Defines filter type. */
  type: FilterType;
  /** Defines filter value. */
  value: Scalars['String']['output'];
};

/** Grouped basket. */
export type GroupedBasket = {
  /** Grouped Baskets collection. */
  basket: Basket;
  /** Basket identifier. */
  groupType: BasketRowsGroupType;
};

/** Collection of grouped basket items. */
export type GroupedBasketsResult = {
  /** Basket identifier. */
  basketId: Scalars['String']['output'];
  /** Grouped Baskets collection. */
  groupedBaskets?: Maybe<Array<GroupedBasket>>;
};

/** Aggregated product item data. */
export type ITotalProductItem = {
  /** Basket item id. */
  basketItemIds: Array<Scalars['String']['output']>;
  /** Brand id. */
  brandId: Scalars['String']['output'];
  /** Image of material. */
  imageUrl: Scalars['String']['output'];
  /** Product Id. */
  productId: Scalars['String']['output'];
  /** Quantity of basket items of material. */
  quantity: Scalars['Int']['output'];
  /** Title of the product. */
  title: Scalars['String']['output'];
};

export type MaterialWidth = {
  id: Scalars['String']['output'];
  width: Scalars['String']['output'];
};

/** Mutations. */
export type Mutation = {
  /** Clear basket. */
  clear?: Maybe<Scalars['String']['output']>;
  /** Basket copy endpoint. */
  copy: Scalars['String']['output'];
  /** Delete basket. */
  delete?: Maybe<Scalars['String']['output']>;
  /** Basket email endpoint. */
  email: Scalars['Boolean']['output'];
  /** Basket submit endpoint. */
  submit: Scalars['Boolean']['output'];
};


/** Mutations. */
export type MutationClearArgs = {
  basketId: Scalars['String']['input'];
};


/** Mutations. */
export type MutationCopyArgs = {
  accountId?: InputMaybe<Scalars['String']['input']>;
  basketId: Scalars['String']['input'];
  preserveOriginalBasket?: InputMaybe<Scalars['Boolean']['input']>;
};


/** Mutations. */
export type MutationDeleteArgs = {
  basketId: Scalars['String']['input'];
};


/** Mutations. */
export type MutationEmailArgs = {
  basketId: Scalars['String']['input'];
  request: BasketEmailRequest;
};


/** Mutations. */
export type MutationSubmitArgs = {
  basketId: Scalars['String']['input'];
  timeZoneId: Scalars['String']['input'];
};

/** Order data. */
export type Order = {
  /** Defines account info. */
  account?: Maybe<OrderAccountInfo>;
  /** Defines address. */
  address?: Maybe<Scalars['String']['output']>;
  /** Defines basket id. */
  basketId?: Maybe<Scalars['String']['output']>;
  /** Defines client reference number. */
  clientReferenceNumber?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (confirmed). */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines order date (placed). */
  orderDate?: Maybe<Scalars['DateTime']['output']>;
  /** Defines order reference number. */
  orderReferenceNumber?: Maybe<Scalars['String']['output']>;
  /** Defines order type. */
  orderType?: Maybe<Scalars['String']['output']>;
  /** Defines net price (confirmed). */
  priceNetConfirmed?: Maybe<OrderItemPrice>;
  /** Defines RRP price. */
  priceRrp?: Maybe<OrderItemPrice>;
  /** Defines date (requested). */
  requestedDate?: Maybe<Scalars['DateTime']['output']>;
  /** Defines quantity (requested). */
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines root product brand id */
  rootBrandId?: Maybe<Scalars['String']['output']>;
  /** Defines root product id */
  rootId?: Maybe<Scalars['String']['output']>;
  /** Defines root product title */
  rootTitle?: Maybe<Scalars['String']['output']>;
  /** Defines sales order number. */
  salesOrderNumber: Scalars['String']['output'];
  /** Defines order date (shipping). */
  shippingDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines SalesOrderLineStatus. */
  status?: Maybe<Scalars['String']['output']>;
  /** Defines thumbnails of products that are part of this request. */
  thumbnails?: Maybe<Array<Scalars['String']['output']>>;
  /** Defines price (net). */
  totalPriceNet?: Maybe<OrderItemPrice>;
  /** Defines tracking numbers. */
  trackingNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Defines unit net price. */
  unitPriceNet?: Maybe<OrderItemPrice>;
  /** Defines unit RRP price. */
  unitPriceRrp?: Maybe<OrderItemPrice>;
  /** Defines order date (updated). */
  updatedDate?: Maybe<Scalars['DateTime']['output']>;
};

/** Account info. */
export type OrderAccountInfo = {
  /** Defines account external id. */
  externalId?: Maybe<Scalars['String']['output']>;
  /** Defines account id. */
  id?: Maybe<Scalars['String']['output']>;
  /** Defines account name. */
  name?: Maybe<Scalars['String']['output']>;
};

/** Search filter options. */
export type OrderFilter = {
  /** Defines filtering option display name. */
  displayName: Scalars['String']['output'];
  /** Defines filtering field. */
  fieldId: Scalars['String']['output'];
  /** Defines if filter values are facets. */
  isFacet: Scalars['Boolean']['output'];
  /** Defines if multiple filter values selection is allowed. */
  isMultiple: Scalars['Boolean']['output'];
  /** Defines filter option order. */
  order: Scalars['Int']['output'];
  /** Defines filter type. */
  type: FilterType;
  /** Array of filter values. */
  values?: Maybe<Array<FilterValue>>;
};

/** Response object for order filters. */
export type OrderFilters = {
  /** Array of filters. */
  filters: Array<OrderFilter>;
  /** Total item count for current filter selection. */
  total: Scalars['Int']['output'];
};

/** Order item data. */
export type OrderItem = {
  /** Defines brand. */
  brandId?: Maybe<Scalars['String']['output']>;
  /** Defines color text. */
  colorText?: Maybe<Scalars['String']['output']>;
  /** Defines order date (confirmed). */
  confirmedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (confirmed). */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines quantity (delivered). */
  deliveredQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines delivery numbers. */
  deliveryNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Defines discount unit value. */
  discount?: Maybe<OrderItemPrice>;
  /** Defines discount percentage. */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Optional product id with removed prefix */
  displayedProductId?: Maybe<Scalars['String']['output']>;
  /** Defines id. */
  id: Scalars['String']['output'];
  /** Defines invoice numbers. */
  invoiceNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Defines markup. */
  markup?: Maybe<Scalars['Float']['output']>;
  /** Defines gross price. */
  priceGross?: Maybe<OrderItemPrice>;
  /** Defines net price. */
  priceNet?: Maybe<OrderItemPrice>;
  /** Defines net price (confirmed). */
  priceNetConfirmed?: Maybe<OrderItemPrice>;
  /** Defines RRP price. */
  priceRrp?: Maybe<OrderItemPrice>;
  /** Defines order date (requested). */
  requestedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (requested). */
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines root id. */
  rootId?: Maybe<Scalars['String']['output']>;
  /** Defines root title. */
  rootTitle?: Maybe<Scalars['String']['output']>;
  /** Defines salesOrderNumber. */
  salesOrderNumber?: Maybe<Scalars['String']['output']>;
  /** Defines order date (shipping). */
  shippingDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines order date (submitted). */
  submittedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Defines tracking numbers. */
  trackingNumbers?: Maybe<Array<Maybe<Scalars['String']['output']>>>;
  /** Defines quantity (unconfirmed). */
  unconfirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount value. */
  unitDiscount?: Maybe<OrderItemPrice>;
  /** Defines unit gross price. */
  unitPriceGross?: Maybe<OrderItemPrice>;
  /** Defines unit net price. */
  unitPriceNet?: Maybe<OrderItemPrice>;
  /** Defines unit RRP price. */
  unitPriceRrp?: Maybe<OrderItemPrice>;
  /** Defines variants. */
  variants?: Maybe<Array<Maybe<VariantItem>>>;
};

/** Order item price. */
export type OrderItemPrice = {
  /** Defines currency value. */
  currency?: Maybe<Scalars['String']['output']>;
  /** Defines price value. */
  value?: Maybe<Scalars['Float']['output']>;
};

export enum OrderType {
  Future = 'FUTURE',
  Mixed = 'MIXED',
  None = 'NONE',
  Now = 'NOW'
}

export type OrderTypeBasketColumn = {
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  orderType?: Maybe<OrderType>;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
};

/** Order paged response */
export type OrdersPartitionedResponse = {
  /** Has next page */
  hasNextPartition: Scalars['Boolean']['output'];
  /** Orders */
  orders: Array<Order>;
  /** Pagination information */
  pagination: PaginationInfo;
  /** Page number */
  partition: Scalars['Int']['output'];
  /** Totals */
  total?: Maybe<OrdersTotalResponse>;
};

export type OrdersTotalResponse = {
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  totalPriceNet?: Maybe<OrderItemPrice>;
};

/** Pagination query */
export type PaginationInfo = {
  afterKey?: Maybe<Scalars['String']['output']>;
  size?: Maybe<Scalars['Int']['output']>;
};

/** Pagination query */
export type PaginationQuery = {
  afterKey?: InputMaybe<Scalars['String']['input']>;
  size?: InputMaybe<Scalars['Int']['input']>;
};

/** Partition filter */
export type PartitionFilter = {
  numberOfPartitions?: InputMaybe<Scalars['Int']['input']>;
  partition: Scalars['Int']['input'];
};

export type PreviewItem = {
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  itemId: Scalars['String']['output'];
  requestedQuantity: Scalars['Int']['output'];
};

export type PriceBasketColumn = {
  actual?: Maybe<Scalars['Float']['output']>;
  currency: Scalars['String']['output'];
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
  secondaryValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

export type QuantityBasketColumn = {
  actual?: Maybe<Scalars['Float']['output']>;
  hidden?: Maybe<Scalars['Boolean']['output']>;
  key: BasketColumnKeys;
  overwritten?: Maybe<Scalars['Boolean']['output']>;
  primaryValue?: Maybe<Scalars['String']['output']>;
  secondaryValue?: Maybe<Scalars['String']['output']>;
  value?: Maybe<Scalars['Float']['output']>;
};

/** Queries. */
export type Query = {
  /** Returns basket data. */
  basket?: Maybe<Basket>;
  /**
   * Obsolete.
   * Returns configurable filters for orderbook
   */
  filter?: Maybe<Array<OrderFilter>>;
  /** Returns account filter for orderbook */
  filterAccounts?: Maybe<Array<OrderFilter>>;
  /** Returns year root filter for orderbook */
  filterYear?: Maybe<Array<OrderFilter>>;
  /** Returns configurable filters for orderbook */
  filters: OrderFilters;
  /** Returns available filter values base on data */
  getBasketManagerFilters?: Maybe<BasketManagerFilterValues>;
  /** Returns filtered data for basket manager */
  getBasketManagerList?: Maybe<BasketManagerResult>;
  /** Returns grouped baskets data. */
  groupedBaskets?: Maybe<GroupedBasketsResult>;
  /** Returns order by order number for orderbook */
  order?: Maybe<Array<Maybe<OrderItem>>>;
  /** Returns orders by configurable filters for orderbook */
  orders: OrdersPartitionedResponse;
  /** Returns lightweight info for baskets */
  searchBaskets?: Maybe<BasketSearchResult>;
  /** Returns lightweight info for users baskets */
  searchUserBaskets?: Maybe<UserBasketsSearchResult>;
};


/** Queries. */
export type QueryBasketArgs = {
  basketId: Scalars['String']['input'];
  groupBy: BasketRowsGroupType;
  sortBy?: InputMaybe<BasketGroupSortType>;
  sortDirection?: InputMaybe<SortDirection>;
};


/** Queries. */
export type QueryFilterArgs = {
  query?: InputMaybe<FilterQuery>;
};


/** Queries. */
export type QueryFilterAccountsArgs = {
  query?: InputMaybe<FilterQuery>;
};


/** Queries. */
export type QueryFilterYearArgs = {
  count?: InputMaybe<Scalars['Int']['input']>;
};


/** Queries. */
export type QueryFiltersArgs = {
  query?: InputMaybe<FilterQuery>;
};


/** Queries. */
export type QueryGetBasketManagerFiltersArgs = {
  query?: InputMaybe<BasketManagerFilterQuery>;
};


/** Queries. */
export type QueryGetBasketManagerListArgs = {
  query?: InputMaybe<BasketManagerListRequest>;
};


/** Queries. */
export type QueryGroupedBasketsArgs = {
  basketId: Scalars['String']['input'];
  groupsBy: Array<BasketRowsGroupType>;
  settings: BasketMappingConfig;
};


/** Queries. */
export type QueryOrderArgs = {
  accountIds?: InputMaybe<Array<InputMaybe<Scalars['String']['input']>>>;
  orderNumber?: InputMaybe<Scalars['String']['input']>;
};


/** Queries. */
export type QueryOrdersArgs = {
  query?: InputMaybe<FilterQuery>;
};


/** Queries. */
export type QuerySearchBasketsArgs = {
  num?: InputMaybe<Scalars['Int']['input']>;
  orderReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  sortBy?: InputMaybe<BasketSearchSortType>;
  sortDirection?: InputMaybe<BasketSearchSortDirection>;
  start?: InputMaybe<Scalars['Int']['input']>;
  statuses?: InputMaybe<Array<Scalars['Int']['input']>>;
};


/** Queries. */
export type QuerySearchUserBasketsArgs = {
  basketsAmount?: InputMaybe<Scalars['Int']['input']>;
  num?: InputMaybe<Scalars['Int']['input']>;
  orderReferenceNumber?: InputMaybe<Scalars['String']['input']>;
  start?: InputMaybe<Scalars['Int']['input']>;
};

/** Filter range type. */
export type RangeFilterValue = {
  /** Filter value count, shows facet hit count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Defines filter value display name. */
  displayName: Scalars['String']['output'];
  /** Array of range values. */
  range?: Maybe<Array<RangeItem>>;
  /** Defines filter type. */
  type: FilterType;
  /** Defines filter value. */
  value: Scalars['String']['output'];
};

/** Filter range value type. */
export type RangeItem = {
  /** Defines filter value display name. */
  displayName: Scalars['String']['output'];
  /** Defines filter value. */
  value: Scalars['String']['output'];
};

export enum ReviewStatus {
  Accepted = 'Accepted',
  None = 'None',
  Pending = 'Pending',
  Rejected = 'Rejected'
}

/**
 * Validation message severity.
 * CRITICAL and ERROR do not allow to continue an order.
 * Other levels are informative.
 */
export enum Severity {
  Critical = 'CRITICAL',
  Debug = 'DEBUG',
  Error = 'ERROR',
  Information = 'INFORMATION',
  Success = 'SUCCESS',
  Warning = 'WARNING'
}

/** Generic group for basket items. */
export type ShippingOption = {
  /** Code of the shipping. */
  code?: Maybe<Scalars['String']['output']>;
  /** Id of the shipping. */
  id: Scalars['String']['output'];
  /** Used flag of the shipping. */
  isUsed?: Maybe<Scalars['Boolean']['output']>;
  /** Name of the shipping. */
  name?: Maybe<Scalars['String']['output']>;
};

export type SimpleFilterValue = {
  count?: Maybe<Scalars['Int']['output']>;
  imageUrl?: Maybe<Scalars['String']['output']>;
  name: Scalars['String']['output'];
  value?: Maybe<Scalars['String']['output']>;
};

export enum SortDirection {
  Asc = 'ASC',
  Desc = 'DESC'
}

/** Search sorting options. */
export type Sorting = {
  /** Defines sorting direction for specified field. */
  direction: SortingDirection;
  /** Defines sorting option display name. */
  displayName: Scalars['String']['output'];
  /** Defines sorting field. */
  fieldId: Scalars['String']['output'];
};

/** Sorting direction. */
export enum SortingDirection {
  Asc = 'Asc',
  Desc = 'Desc'
}

/** Basket Summary */
export type Summary = {
  /** Number Of Items in the Basket */
  numberOfItems?: Maybe<Scalars['Int']['output']>;
  /** Total Basket product pieces count */
  totalPiecesQuantity: Scalars['Int']['output'];
  /** Total Basket value per currency */
  totalValueByCurrency?: Maybe<Scalars['ValueByCurrency']['output']>;
};

/** Summarized data. */
export type Total = {
  /** Aggrgated colors data. */
  colors?: Maybe<Array<TotalColorItem>>;
  /** Totals for confirmed basket items */
  confirmed?: Maybe<TotalItem>;
  /** Aggrgated discounts. */
  discounts?: Maybe<TotalDiscounts>;
  /** Manual discount */
  manualDiscount?: Maybe<Scalars['Float']['output']>;
  /** Aggregated products data. */
  products?: Maybe<Array<TotalProductItem>>;
  /** Product quantity ratio. */
  quantityRatio?: Maybe<Scalars['Float']['output']>;
  /** Totals for requested basket items. */
  requested: TotalItem;
  /** Totals for confirmed basket items */
  simulated?: Maybe<TotalItem>;
  /** Product WSP ratio. */
  wspRatio?: Maybe<Scalars['Float']['output']>;
};

export type TotalColorItem = {
  /** Hex representation of product color. */
  hex: Scalars['String']['output'];
  /** Number of quantities per color. */
  quantity: Scalars['Int']['output'];
};

export type TotalDiscounts = {
  /** Discount by retail price */
  rrp?: Maybe<Scalars['Float']['output']>;
  /** Discount by gross price */
  wsp?: Maybe<Scalars['Float']['output']>;
};

/** Total item. */
export type TotalItem = {
  /** Currency. */
  currency: Scalars['String']['output'];
  /** Rrp Currency. */
  currencyRrp: Scalars['String']['output'];
  /**
   * Total WSP Displayed.
   * wspd ?? wsp
   */
  displayedwsp: Scalars['Float']['output'];
  /** Total NET Price, is a WSP price confirmed by ERP. */
  net: Scalars['Float']['output'];
  /**
   * Total requested quantity. This originates from RequestedQuantity or ConfirmedQuantity.
   * The ConfirmedQuantity can be undefined.
   */
  quantity?: Maybe<Scalars['Int']['output']>;
  /** Total RRP. */
  rrp: Scalars['Float']['output'];
  /** Total WSP. */
  wsp: Scalars['Float']['output'];
  /** Total WSPD. */
  wspd: Scalars['Float']['output'];
};

/** Aggregated product item data. */
export type TotalProductItem = ITotalProductItem & {
  /** Basket item id. */
  basketItemIds: Array<Scalars['String']['output']>;
  /** Brand id. */
  brandId: Scalars['String']['output'];
  /** Image of material. */
  imageUrl: Scalars['String']['output'];
  /** Close out from product */
  isCloseOutProduct?: Maybe<Scalars['Boolean']['output']>;
  /** Product Id. */
  productId: Scalars['String']['output'];
  /** Quantity of basket items of material. */
  quantity: Scalars['Int']['output'];
  /** Title of the product. */
  title: Scalars['String']['output'];
};

/** User with newest created Baskets */
export type UserBaskets = {
  baskets?: Maybe<Array<Maybe<BasketSlim>>>;
  user?: Maybe<UserExtended>;
};

/** Paginated result for lightweight basket model */
export type UserBasketsSearchResult = {
  items?: Maybe<Array<Maybe<UserBaskets>>>;
  total?: Maybe<Scalars['Int']['output']>;
};

/** Basket User */
export type UserExtended = {
  displayName?: Maybe<Scalars['String']['output']>;
  emailAddress?: Maybe<Scalars['String']['output']>;
  id: Scalars['String']['output'];
  profilePictureUrl?: Maybe<Scalars['String']['output']>;
  userName?: Maybe<Scalars['String']['output']>;
};

/** Generic validation result. */
export type ValidationResult = {
  /** Validation message. */
  message?: Maybe<Scalars['String']['output']>;
  /**
   * Validation message severity.
   * CRITICAL and ERROR do not allow to continue an order.
   * Other levels are informative.
   */
  severity: Severity;
  /** Validation message. */
  translationKey?: Maybe<Scalars['String']['output']>;
  /** Validation result values. */
  values?: Maybe<Array<Scalars['String']['output']>>;
};

/** Variant item. */
export type VariantItem = {
  /** Defines order date (confirmed). */
  confirmedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (confirmed). */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines quantity (delivered). */
  deliveredQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount unit value. */
  discount?: Maybe<OrderItemPrice>;
  /** Defines discount percentage. */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Defines markup. */
  markup?: Maybe<Scalars['Float']['output']>;
  /** Defines gross price. */
  priceGross?: Maybe<OrderItemPrice>;
  /** Defines net price. */
  priceNet?: Maybe<OrderItemPrice>;
  /** Defines RRP price. */
  priceRrp?: Maybe<OrderItemPrice>;
  /** Defines order date (requested). */
  requestedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (requested). */
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines root id. */
  rootId?: Maybe<Scalars['String']['output']>;
  /** Defines order date (shipping). */
  shippingDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines variants. */
  sizes?: Maybe<Array<Maybe<VariantSize>>>;
  /** Defines order date (submitted). */
  submittedDeliveryDate?: Maybe<Scalars['String']['output']>;
  /** Defines SalesOrderLineStatus. */
  type?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (unconfirmed). */
  unconfirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount value. */
  unitDiscount?: Maybe<OrderItemPrice>;
  /** Defines unit gross price. */
  unitPriceGross?: Maybe<OrderItemPrice>;
  /** Defines unit net price. */
  unitPriceNet?: Maybe<OrderItemPrice>;
  /** Defines unit RRP price. */
  unitPriceRrp?: Maybe<OrderItemPrice>;
};

/** Variant Size. */
export type VariantSize = {
  /** Defines quantity (confirmed). */
  confirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines variant size count. */
  count?: Maybe<Scalars['Int']['output']>;
  /** Defines quantity (delivered). */
  deliveredQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount unit value. */
  discount?: Maybe<OrderItemPrice>;
  /** Defines discount percentage. */
  discountPercentage?: Maybe<Scalars['Float']['output']>;
  /** Defines gross price. */
  priceGross?: Maybe<OrderItemPrice>;
  /** Defines net price. */
  priceNet?: Maybe<OrderItemPrice>;
  /** Defines RRP price. */
  priceRrp?: Maybe<OrderItemPrice>;
  /** Defines quantity (requested). */
  requestedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines variant size title. */
  title?: Maybe<Scalars['String']['output']>;
  /** Defines quantity (unconfirmed). */
  unconfirmedQuantity?: Maybe<Scalars['Int']['output']>;
  /** Defines discount value. */
  unitDiscount?: Maybe<OrderItemPrice>;
  /** Defines unit gross price. */
  unitPriceGross?: Maybe<OrderItemPrice>;
  /** Defines unit net price. */
  unitPriceNet?: Maybe<OrderItemPrice>;
  /** Defines unit RRP price. */
  unitPriceRrp?: Maybe<OrderItemPrice>;
  /** Defines variant size width. */
  width?: Maybe<Scalars['String']['output']>;
};
