import { getValue } from './imports';

export const arrayIsEmpty = <T>(array: T[]): boolean => {
  return array.length === 0;
};

export const arrayIsNotEmpty = <T>(array: T[]): boolean => !arrayIsEmpty(array);

// to be used as array.filter(unique)
// works well only with primitive types
// tslint:disable-next-line:no-any
export function unique<T extends any>(value: T, index: number, self: T[]) {
  return self.indexOf(value) === index;
}

export function uniqueBy<T>(
  array: T[],
  key: keyof T,
  ...keys: (keyof T)[]
): T[] {
  const allKeys = [key, ...keys];

  function formatter(item: T) {
    return allKeys.map((k) => item[k]).join('_');
  }
  return filterArray(array, formatter);
}

export function uniqueByPropertyPath<T extends object>(
  array: T[],
  key: string,
  ...keys: string[]
): T[] {
  const allKeys = [key, ...keys];

  function formatter(item: T) {
    return allKeys.map((k) => getValue(item, k)).join('_');
  }

  return filterArray(array, formatter);
}

export function filterArray<T>(array: T[], formatter: (i: T) => string): T[] {
  const seen = new Set<string>();
  return array.filter((item) => {
    const formatted = formatter(item);

    if (seen.has(formatted)) {
      return false;
    }

    seen.add(formatted);
    return true;
  });
}

export type UnknownDimensionArray<T> = UnknownDimensionArray<T>[];
