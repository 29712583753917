import { FilterQueryParams } from '@epicuro-next/models/products';
import { SearchQuery } from 'graphql-api/gql-api-interfaces';

export function transformFilterQueryParamToSearchQuery(
  params: FilterQueryParams | null,
): SearchQuery | null {
  if (!params) {
    return null;
  }

  const sites = params.subsites?.split(',');
  return {
    ...params,
    subsites: params.subsites !== '' && sites ? sites : [],
  };
}
